function updateStatus(message) {
    const statusEl = document.getElementById('status');
    if (statusEl) {
        statusEl.innerText = message;
    }
}
window.addEventListener('load', async () => {
    // Verify user's web browser has necessary support
    const unsupported = [
        ['serviceWorker', window.navigator.serviceWorker],
        ['BigInt', window.BigInt],
        ['WebAssembly', window.WebAssembly],
    ]
        .filter((tuple) => !tuple[1])
        .map((tuple) => tuple[0])
        .join(', ');
    if (unsupported) {
        updateStatus(`This web browser cannot interact with the Internet Computer securely.  (No: ${unsupported})
       Please try new web browser software.`);
    }
    else {
        console.log('Installing a service worker to proxy and validate raw content into the browser...');
        // Ok, let's install the service worker...
        // note: if the service worker was already installed, when the browser requested <domain>/, it would have
        // proxied the response from <domain>/<canister-id>/, so this bootstrap file would have never been
        // retrieved from the boundary nodes
        await navigator.serviceWorker.register('sw.js');
    }
});
